import React from 'react';
import styled from "styled-components"
import QouteIconOpen from "../images/quote-icon.png"
const SlideWrap= styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    padding-top:10px;
    overflow:hidden;
    @media screen and (max-width: 991px) {
        align-items:flex-start;
        flex-direction:column-reverse;
        padding-top:30px;
    }    
`;
const SlideQuoteContainer = styled.div`
    width:50%;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    transition:all 0.5s;
    padding-right:50px;
    position:relative;
    @media screen and (max-width: 991px) {
        width: 100%;
        padding-top:50px;
        padding-right:0px;
    }   
`;
const SlideImageWrap = styled.div`
    width:50%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
    @media screen and (max-width: 991px) {
        width: 100%;
    }
`;
const SlideImageContainer = styled.div`
    width: 85%;
    margin-right:auto;
    border-radius: 30px;
    position:relative;
    outline:none;
    border:none;
    @media screen and (max-width: 991px) {
        width: 100%;
    }
`;
const SlideImg = styled.img`
    width:100%;
    height:100%;
    object-fit:cover;
    object-position:center;
    border-radius: 30px;
    margin:0;
    background:#f8f8f8;
    outline:none;
    border:none;
    height: 348px;
    @media screen and (max-width: 991px) {
        height:400px;
    } 
    @media screen and (max-width: 479px) {
        height:300px;
    } 
`;
const Overlay = styled.div`
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background-image: linear-gradient(to bottom, rgba(113, 113, 113, 0) 45%, rgba(0,0,0,0.9) 95%);
    border-radius: 30px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-end;
    padding:10px;
    overflow:hidden;
`;
const Logo = styled.img`
    width:230px;
    margin:0;
    transition:all 0.5s ease;
`;
const BlurImage = styled.img`
    position:absolute;
    top:22%;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    width:80%;
    height:80%;
    z-index:-1;
    filter:blur(39px);
    border-radius:30px;
`;
const FounderName = styled.h1`
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #2e3439;
    margin:40px 0 0 0;
    transition:all 0.5s ease;
    @media screen and (max-width: 991px) {
        text-align:center;
        width: 620px;
    } 
    @media screen and (max-width: 479px) {
        width:100%;
        font-size: 18px;
    } 
`;
const BrandName = styled.h1`
    margin:0px; 
    font-family: Roboto;
    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #2e3439;
    transition:all 0.5s ease;
    @media screen and (max-width: 991px) {
        text-align:center;
        width: 620px;
    } 
    @media screen and (max-width: 479px) {
        width:100%;
        font-size: 30px;
    } 
`;
const Quote = styled.h3`
    font-family: Montserrat;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    font-size:20px;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color:rgba(46,52,57,1);
    margin-top:0px;
    margin-bottom:0px;
    position:relative;
    z-index:10;
    @media screen and (max-width: 479px) 
        font-size: 16px !important;
    } 
`;
const QouteIcon = styled.img`
    width:120px;
    height:auto;
    position:absolute;
    top:0;
    left:0;
    right:auto;
    bottom:auto;
    z-index:-1;
    margin-left:-10px;
    opacity:0.7;
    @media screen and (max-width: 479px) {
        width:70px;
        margin-left:0px;
    } 
`;
const InvestorSlide = (props) => {
    return (
        <SlideWrap>
            <SlideQuoteContainer id="investor-quote">
                <Quote dangerouslySetInnerHTML={{ __html: props.quote}}></Quote>
                <QouteIcon src={QouteIconOpen}></QouteIcon>
            </SlideQuoteContainer>
            <SlideImageWrap>
                <SlideImageContainer>
                    <SlideImg src={props.image}/>
                    <Overlay>
                        <Logo src={props.logo} className="investorLogo"></Logo>
                    </Overlay>
                    {/* <BlurImage src={props.image}></BlurImage> */}
                </SlideImageContainer>   
                <FounderName className="investorFounder">{props.founders}</FounderName> 
                <BrandName className="investorBrand">{props.name}</BrandName>
            </SlideImageWrap>                    
        </SlideWrap>
    );
};

export default InvestorSlide;