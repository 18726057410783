import * as React from "react"
import styled from 'styled-components'
import Layout from "../components/layout"
import Seo from "../components/seo"
import SectionHeading from "../components/SectionHeading"
import SectionParagraph from "../components/SectionParagraph"
import HomeBannerSlider from "../components/HomeBannerSlider"
import SectionButton from "../components/SectionButton"
import HomeTeamSlider from "../components/HomeTeamSlider"
import Background from "../images/section-background.png"
import InvestorSlider from "../components/InvestorSlider"
import HomePortfolioSlider from "../components/HomePortfolioSlider"
import LogoBlock from "../components/LogoBlock"
import Favicon from "../images/w-health-favicon.png"
const HomeBannerSection = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    height:768px;
    position:relative;
    background:#f7f7f7;
    @media screen and (max-width: 991px) {
        flex-direction:column;
        height:auto;
    }
    @media screen and (max-width: 767px) {
      
    }
    @media screen and (max-width: 479px) {      
        
    }
    `;
const HomeBannerContentWrap = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    width:50%;
    padding:0 2.5% 0 2.5%;
    height:768px;
    @media screen and (max-width: 991px) {
      width:100%;
      margin-bottom:100px;
    }
    @media screen and (max-width: 767px) {
      height:auto;
      padding:0 2.5% 60px 2.5%;
    }
    
    @media screen and (max-width: 479px) {
      margin-bottom:0px;
    }
`;
const HomeSliderContainer = styled.div`
    width:50%;
    height:768px; 
    z-index:3;
    @media screen and (max-width: 991px) {
        width:100%;
        height:768px;
    }
    @media screen and (max-width: 479px) {
      width:100%;
      height:368px;
  }
`;
const TeamSliderSection = styled.div`
    width:100%;
    height:300px;
    background:#ebeef5;
    position:relative;
    display: flex;
    @media screen and (max-width: 991px) {
        flex-direction:column;
        height:auto;
        padding:20px 0 20px 0;
    }
    @media screen and (max-width: 479px) {
      padding:40px 0 40px 0;
      background:#fff;
  }
`;
const TeamSliderContainer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  width:100%;
  height:700px;
  position:relative;
  @media screen and (max-width: 991px) {
    height:600px;
}
  @media screen and (max-width: 479px) {
    height:auto;
  }
`;
const TeamSliderContentWrap = styled.div`
  width:30vw;
  margin-left:0;
  margin-right:auto;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  height: 100%;
  justify-content: center;
  padding-left:2.5vw;
  @media screen and (max-width: 991px) {
      width:30vw;
  }
  @media screen and (max-width: 479px) {
      width:100%;
  }
`;
const HomePortfolioSection = styled.div`
    background:#f7f7f7;
    width:100%;
    position:relative;
    padding:100px 2.5vw 100px 2.5vw;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    @media screen and (max-width: 479px) {
      padding:40px 2.5vw 40px 2.5vw;
  }
`;
const BackgroundImage = styled.img`
    min-height:100%;
    width:80%;
    object-fit:cover;
    object-position:50% 50%;
    position:absolute;
    top:-100px;
    right:auto;
`;

const ExternalButtonContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    padding: 0px 0 50px 0;
`;
const InvestorSection = styled.div`
    position:relative;
    display: flex;
    flex-direction:column;
    padding:50px 2.5vw 100px 2.5vw;
    @media screen and (max-width: 991px) {
  
    }
`;
const InvestorContent = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    @media screen and (max-width: 991px) {
      margin-bottom:20px;
    }
`;
const BackSection = styled.div`
    position:absolute;
    top:0;
    left:auto;
    right:0%;
    height:100%;
    z-index:-1;
    display: flex;
    flex-direction: column;
    width: 70%;
    background-image:url(${Background});
`;
const IndexPage = () => (
  <Layout>
    <Seo title="Home" url="https://whealthventures.com" image={Favicon} />
    <HomeBannerSection>
      <HomeBannerContentWrap>
        <LogoBlock></LogoBlock>
        <SectionHeading top={"15vh"} mobileTop={"80px"}>Building <br />the Future of <br />Healthcare Delivery</SectionHeading>
        <SectionParagraph>We invest in and grow tech-enabled early-stage healthcare companies <br /> that are disrupting care delivery in the US and India.</SectionParagraph>
        <SectionButton to="/about-us" homeBanner={true}>About Us</SectionButton>
      </HomeBannerContentWrap>
      <HomeSliderContainer>
        <HomeBannerSlider home={true} />
      </HomeSliderContainer>
    </HomeBannerSection>
    <HomePortfolioSection>
      <BackgroundImage src={Background}></BackgroundImage>
      <SectionHeading>Portfolio</SectionHeading>
      {/* <PortfolioSlider></PortfolioSlider> */}
      <HomePortfolioSlider></HomePortfolioSlider>
      <SectionButton to="/portfolio" top={"50px"} left={"auto"} right={"5vw"}>See all</SectionButton>
    </HomePortfolioSection>
    <TeamSliderContainer>
      <TeamSliderSection>
        <TeamSliderContentWrap>
          <SectionHeading>Team</SectionHeading>
          <SectionParagraph>We are committed to changing the way the world experiences healthcare.</SectionParagraph>
          <SectionButton to="/team" top={"50px"}>Know More</SectionButton>
        </TeamSliderContentWrap>
        <HomeTeamSlider></HomeTeamSlider>
      </TeamSliderSection>
    </TeamSliderContainer>

    <InvestorSection>
      <SectionHeading>Founder Testimonials</SectionHeading>
      {/* <InvestorContent>
            <SectionHeading >We partner with visionary entrepreneurs to scale healthcare delivery models.</SectionHeading>
            <SectionParagraph top={"20px"}>We bring years of deep healthcare and technology experience. We are entrepreneurs at heart. We are connected to the healthcare ecosystem across the globe.  </SectionParagraph>
            <SectionButton to="/pitch-to-us" top={"20px"} >Pitch To Us</SectionButton>
        </InvestorContent>  */}
      <InvestorSlider></InvestorSlider>
      <BackSection></BackSection>
    </InvestorSection>
  </Layout>
)

export default IndexPage
