import React from 'react';
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PortfolioWysa from "./PortfolioWysa"
import PortfolioBeato from "./PortfolioBeato"
import PortfolioRyse from "./PortfolioRyse"
import HomePortfolioSlide from "./HomePortfolioSlide"
const SliderWrap = styled.div`
    width:100%;
    margin-top:50px;
    @media screen and (max-width: 767px) {
      margin-bottom:30px;
    }
    @media screen and (max-width: 479px) {
      margin-top:20px;
    }
    
`;
const SliderDots = styled.div`
    
`;
const SliderDot = styled.div`
`;
const SliderData = [
  {
    "image": "../images/Wysa_Card.png",
    "logo": "../images/WysaLogo.png",
    "description": "Wysa is creating accessible mental health support at scale, by using technology to create experiences that allow people to manage their mental health anytime, anywhere, anonymously.",
    "link": "/portfolio/wysa",
  },
  {
    "image": "../images/BeatO_Card.jpg",
    "logo": "../images/BeatOLogo.png",
    "description": "BeatO is a comprehensive personalized chronic condition management platform. It offers a connected glucometer and a partner app for blood glucose monitoring which creates a tailored diabetes care plan for patients.",
    // "link":"#",
  },
  {
    "image": "../images/Ryse_Card.jpg",
    "logo": "../images/Ryse health_trasnparent logo.png",
    "description": "Ryse Health is on a mission to free the world from the burden of chronic disease through a nationwide network of tech-enabled practices providing care to thousands of patients across multiple chronic diseases.",
    // "link":"#",
  },
  {
    "image": "../images/Mars_Card.jpg",
    "logo": "../images/Mars_GHC.png",
    "description": "Good Health Company is a full-stack solution for patients with conditions like hair issues, sexual health, weight loss and skincare. GHC has its own men’s health products under the sub-brand ‘Mars’ and provides unlimited free consultations with their team of experts, and recommends a personalized course of treatment.",
    // "link":"#",
  },
  {
    "image": "../images/Jasper-card.png",
    "logo": "../images/Jasper-logo.png",
    "description": "Jasper Health is a comprehensive oncology platform that simplifies the cancer care journey to improve the lives of people living with cancer, those at high risk of cancer, and their caregivers.",
    // "link":"#",
  },
  {
    "image": "../images/Mylo_Card.png",
    "logo": "../images/mylo_logo.png",
    "description": "Mylo is a full-stack platform that acts as a close companion to expecting and young parents. The platform provides a personalized experience, a helpful community of mothers & experts, and curated products – everything a parent needs for their parenting journey.",
    // "link":"#",
  },
  {
    "image": "../images/Kins_Card_Website.jpg",
    "logo": "../images/Kins_Color.png",
    "description": "Kins is a modern, hybrid, physical therapy program that enables therapists and patients to collaborate in creating a personalized course with a mix of in-person and virtual care.",
    // "link":"#",
  },

]
export default function HomePortfolioSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    focusOnSelect: false,
    autoplaySpeed: 3500,
    swipeToSlide: false,
    height: 500,
    arrows: false,
    fade: true,
    appendDots: dots => (
      <SliderDots>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </SliderDots>
    ),
    customPaging: i => (
      <SliderDot>
        {i + 1}
      </SliderDot>
    ),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <SliderWrap id="homePortfolio">
      <Slider {...settings}>
        {SliderData.map((row, index) => (
          <HomePortfolioSlide key={index} data={row}></HomePortfolioSlide>
        ))}
        {/* <PortfolioWysa></PortfolioWysa>
              <PortfolioBeato></PortfolioBeato>
              <PortfolioRyse></PortfolioRyse> */}
      </Slider>
    </SliderWrap>

  );
}