import React from 'react';
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InvestorSlide from "../components/InvestorSlide"
const SliderWrap=styled.div`
    width:100%;
    height:500px;
    overflow:hidden;
    @media screen and (max-width: 991px) {
      height:auto;
      margin-top:50px;
    } 
    @media screen and (max-width: 767px) {
      height:auto;
      margin-top:30px;
    } 
    @media screen and (max-width: 479px) {
      margin-top:0px;
    } 
`;
const InvestorData= [
    {
        "name":"WYSA",
        "founders":"Jo Aggarwal & Ramakant Vempati",
        "image":"../images/Wysa Founder photos.jpg",
        "logo":"../images/WysaLogo.png",
        "quote":"We were looking for investors who will increase our confidence and clarity in achieving our goals. That is what we found with W Health Ventures. <br/><br/>It feels like there is nothing we can’t achieve together. Just in the few months between term sheet and signing, the team had already helped us establish our US operations, start hiring conversations with stellar candidates in the space and made innumerable warm introductions to the health tech ecosystem. <br/><br/> There is a sense of possibility, shared sense of purpose and sheer momentum that our partnership with W Health has brought to Wysa!"
    },
    // {
    //     "name":"Good Health Company",
    //     "founders":"Samarth Sindhi",
    //     "image":"",
    //     "logo":"../images/Mars_GHC.png",
    // },
    {
        "name":"Ryse Health",
        "founders":"Erin Kane & Richard Gurley",
        "image":"../images/Ryse_Founders.jpg",
        "logo":"../images/Ryse health_trasnparent logo.png",
        "quote":"The W Health team is always one of our first stops when working through knotty finance, clinical, legal or operations questions. The team is perceptive, thoughtful, and brings forward key insights and introductions at exactly the right moments.<br/><br/>Most impressive has been their deep understanding of healthcare delivery and digital health - it is rare to find investors that truly understand healthcare operations at the patient level and can also bring strategic vision. We'd partner with W Health again in a heartbeat."
    },
];
export default function InvestorSlider() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      height:500,
      arrows:false,
      swipeToSlide: true,
      fade:true,
      appendDots: dots => (
        <div>
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <div>
            {i + 1}
        </div>
      ),
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          }
        },
        {
          breakpoint: 479,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    };
    
    return (
      <SliderWrap id="investorSlider">
          <Slider {...settings}>
            {InvestorData.map((brand, index) => (
                <InvestorSlide key={index} name={brand.name} founders={brand.founders} image={brand.image} logo={brand.logo} quote={brand.quote}></InvestorSlide>
            ))}
          </Slider>
      </SliderWrap>
      
    );
  }