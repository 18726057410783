import React from 'react';
import styled from 'styled-components'
import Ryse1 from "../images/Ryse_Card.jpg" 
// import Wysa2 from "../images/wysa-mobile2.png" 
import RyseLogo from "../images/Ryse health_trasnparent logo.png"
import SectionParagraph from "../components/SectionParagraph"
const RyseWrap=styled.div`
    display:flex;
    height:auto;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    width:100%;
    @media screen and (max-width: 991px) {
        flex-direction:column;
    }
    @media screen and (max-width: 767px) {
        height:auto;
    }
    overflow:hidden;
`;
const RyseImageContainer=styled.div`
    width:50%;
    height:100%;
    display:flex;
    padding-left:3%;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    border-radius:30px;
    @media screen and (max-width: 991px) {
        width:100%;
        padding-left:6%;
    }
    @media screen and (max-width: 479px) {
        padding-left:0%;
    }
`;
const RyseImgOne=styled.img`
    height:350px;
    width:100%;
    object-position:center;
    object-fit:cover;
    border-radius:30px;
    transition:all 0.5s ease;    
    background:#f7f7f7;
    @media screen and (max-width: 991px) {
        height:350px;
        object-position:top;
    }
    @media screen and (max-width: 479px) {
        height:auto;
        object-position:50% 50%;
        object-fit:contain;
    }
`;
const RyseImgTwo=styled.img`
    height:350px;
    width:auto;
    transition:all 0.5s ease;
    object-fit:contain;
    @media screen and (max-width: 991px) {
        height:300px;
    }
    @media screen and (max-width: 479px) {
        height:200px;
    }
`;
const RyseContentContainer = styled.div`
    width:50%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:center;
    padding-left:100px;
    overflow:hidden;
    transition:all 0.5s ease;
    @media screen and (max-width: 991px) {
        width:100%;
        padding-left:20%;
    }
    @media screen and (max-width: 479px) {
        padding-left:0px;
    }
`;
const RyseLogoImg=styled.img`
    width:260px;
    height:auto;
    margin-bottom:60px;
    @media screen and (max-width: 991px) {
        width:200px;
        margin-bottom:20px;
    }
`;

const PortfolioRyse = (props) => {
    return (
        <RyseWrap>
            <RyseImageContainer>                      
                <RyseImgOne src={Ryse1} id="ryse-img1" ></RyseImgOne>
                {/* <RyseImgTwo src={Wysa2} id="ryse-img2"></RyseImgTwo>                 */}
            </RyseImageContainer>            
            <RyseContentContainer id="ryse-content" >
                <RyseLogoImg src={RyseLogo}/>
                <SectionParagraph width={"80%"}>Ryse Health is on a mission to free the world from the burden of chronic disease through a nationwide network of tech-enabled practices providing care to thousands of patients across multiple chronic diseases in the US.</SectionParagraph>
            </RyseContentContainer>                
        </RyseWrap>
    );
};

export default PortfolioRyse;