import React from 'react';
import styled from 'styled-components';

const SingleTeam = styled.div`
    width:300px;
    height:500px;
    position:relative;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    @media screen and (max-width: 479px) {
        width:250px;
      }
`;
const TeamMember = styled.div`
    width: 175px;
    height: 175px;
    border-radius:30px;
    position:relative;
    transition:all 1s ease;
    @media screen and (max-width: 479px) {
        width:150px;
        height:150px;
      }
`;
const MemberName = styled.h1`
    font-family: Montserrat;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    opacity:0;
    transform:scale(0.5);
    transition:all 0.4s ease;
    width:100%;
    margin:0;
`;
const MemberDesignation = styled.h2`
    width:100%;
    margin:0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    opacity:0;
    transition:all 0.4s ease;
`;
const MemberImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit:cover;
    border-radius: 30px;    
    filter: grayscale(100%);
`;
const Overlay = styled.div`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-image: linear-gradient(to bottom, rgba(113, 113, 113, 0) 66%, #393939 95%);
    border-radius: 30px;
    display:flex;
    flex-direction:column;
    align-items:flex-end;
    justify-content:flex-end;
    padding:10px;

`;

const TeamSlide = (props) => {
    return (
        <SingleTeam className="single-team" key={props.key}>
            <TeamMember className="team-member" >
                <MemberImage src={props.data.teamDetails.image.mediaItemUrl}></MemberImage>
                <Overlay>
                    <MemberName className="member-name">{props.data.teamDetails.name}</MemberName>
                    <MemberDesignation className="member-des">{props.data.teamDetails.designation}</MemberDesignation>
                </Overlay>
            </TeamMember>
        </SingleTeam>        
    );
};

export default TeamSlide;