import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components'
import TeamSlider from "../components/TeamSlider"
const SliderContainer= styled.div`
    width:850px;
    height:500px;
    margin-left:auto;
    margin-right:0px;
    display:block;
    position:absolute;
    left:auto;
    right:0;
    margin-top:-100px;
    @media screen and (max-width: 991px) {
        width:60%;
    }
    @media screen and (max-width: 479px) {
        width:100%;
        position:relative;
        margin-top:50px;
    }
`;
const HomeTeamSlider = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        height:500,
        autoplay: false,
        focusOnSelect: true,
        pauseOnHover: false,
        autoplaySpeed: 5000,
        arrows:false,
        appendDots: dots => (
          <div>
            <ul style={{ margin: "0px" }}> {dots} </ul>
          </div>
        ),
        customPaging: i => (
          <div>
              {i + 1}
          </div>
        ),
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 479,
            settings: {
              slidesToShow: 2,
            }
          }
        ]
      };
    return (
        <SliderContainer id="team-slider">
            <TeamSlider settings={settings}>

            </TeamSlider>
        </SliderContainer>
    );
};

export default HomeTeamSlider;