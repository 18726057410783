import React, { useState,useEffect } from 'react';
import {Link} from "gatsby"
import styled from 'styled-components'
import Wysa1 from "../images/Wysa_Card.png" 
// import Wysa2 from "../images/wysa-mobile2.png" 
import WysaLogo from "../images/WysaLogo.png"
import SectionParagraph from "./SectionParagraph"
const WysaWrap=styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    width:100%;
    @media screen and (max-width: 991px) {
        flex-direction:column;
        height:auto;
    }
    @media screen and (max-width: 767px) {
        height:auto;
      }
    overflow:hidden;
`;
const WysaImageContainer=styled(Link)`
    text-decoration:none;
    margin-bottom:0px;
    width:50%;
    padding-left:3%;
    height:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    @media screen and (max-width: 991px) {
        padding-left:6%;
        width:100%;
    }
    @media screen and (max-width: 479px) {
        padding-left:0%;
    }
`;
const WysaImgOne=styled.img`
    height:350px;
    background:#f7f7f7;
    width:100%;
    transition:all 0.5s ease;
    object-position:center;
    object-fit:cover;
    border-radius:30px;
    @media screen and (max-width: 991px) {
        height:350px;
        object-position:bottom;
    }
    @media screen and (max-width: 479px) {
        height:auto;
        object-position:50% 50%;
        object-fit:contain;
    }
`;
const WysaImgTwo=styled.img`
    height:350px;
    width:auto;
    transition:all 0.5s ease;
    object-fit:contain;
    @media screen and (max-width: 991px) {
        height:300px;
    }
    @media screen and (max-width: 479px) {
        height:200px;
    }
`;
const WysaContentContainer = styled.div`
    width:50%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:center;
    padding-left:100px;
    overflow:hidden;
    transition:all 0.5s ease;
    @media screen and (max-width: 991px) {
        width:100%;
        padding-left:20%;
    }
    @media screen and (max-width: 479px) {
        padding-left:0px;
    }
`;
const WysaLogoImg=styled.img`
    width:260px;
    height:auto;
    margin-bottom:60px;
    @media screen and (max-width: 991px) {
        width:200px;
        margin-bottom:20px;
    }
`;
const PortfolioWysa = (props) => {
    
    return (
        <WysaWrap>
            <WysaImageContainer to="/portfolio/wysa">
                <WysaImgOne src={Wysa1} id="wysa-img1"></WysaImgOne>
                {/* <WysaImgTwo src={Wysa2} id="wysa-img2"></WysaImgTwo> */}
            </WysaImageContainer>            
            <WysaContentContainer id="wysa-content" >
                <WysaLogoImg src={WysaLogo}/>
                <SectionParagraph width={"70%"} mobile={"90%"}>Wysa is creating accessible mental health support at scale, by using technology to create experiences that allow people to manage their mental health anytime, anywhere, anonymously.</SectionParagraph>
            </WysaContentContainer>
        </WysaWrap>
    );
};

export default PortfolioWysa;