import React from 'react';
import styled from 'styled-components'
import Beato1 from "../../static/images/Mars_Card.jpg" 
// import Beato2 from "../images/Beato-mobile2.png" 
// import Beato3 from "../images/Beato-mobile3.png" 
import BeatoLogo from "../../static/images/Mars_GHC.png"
import SectionParagraph from "./SectionParagraph"
const BeatoWrap=styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    width:100%;
    height:100%;
    overflow:hidden;
    position:relative;
    @media screen and (max-width: 991px) {
        flex-direction:column;
        height:auto;
    }
    @media screen and (max-width: 767px) {
        flex-direction:column;
        height:auto;
    }
`;
const BeatoImageContainer=styled.div`
    width:50%;
    height:100%;
    display:flex;
    padding-left:3%;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    position:relative;
    @media screen and (max-width: 991px) {
        width:100%;
        padding-left:6%;
        justify-content: center;
    }
    @media screen and (max-width: 767px) {
        padding-left:0%;
    }
`;
const BeatoImgOne=styled.img`
    width:100%;
    height:350px;
    position:relative;
    top:0;
    left:auto;
    right:auto;
    transition:all 0.5s ease;
    object-position:center;
    object-fit:cover;
    background:#f7f7f7;
    border-radius:30px;
    @media screen and (max-width: 991px) {
        height:350px;
        object-position:top;
    }
    @media screen and (max-width: 479px) {
        height:auto;
        object-position:50% 50%;
        object-fit:contain;
    }
`;
const BeatoImgTwo=styled.img`
    width:100%;
    height:100%; 
    position:absolute;
    left:auto;
    right:auto;
    transition:all 0.5s ease;
    object-fit:contain;
`;
const BeatoImgThree=styled.img`
    width:100%;
    height:100%;    
    top:0;
    left:auto;
    right:auto;
    position:absolute;
    transition:all 0.5s ease;
    object-fit:contain;
`;
const BeatoContentContainer = styled.div`
    width:50%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:center;
    padding-left:100px;
    transition:all 0.5s ease;
    @media screen and (max-width: 991px) {
        width:100%;
        padding-left:20%;
    }
    @media screen and (max-width: 479px) {
        padding-left:0px;
    }
`;
const BeatoLogoImg=styled.img`
    width:200px;
    height:auto;
    margin-bottom:60px;
    @media screen and (max-width: 991px) {
        width:180px;
        margin-bottom:20px;
    }
`;

const PortfolioBeato = (props) => {
    return (
        <BeatoWrap>
                <BeatoImageContainer>                   
                    <BeatoImgOne src={Beato1} id="beato-img1"></BeatoImgOne>
                    {/* <BeatoImgTwo src={Beato2} id="beato-img2"></BeatoImgTwo>            
                    <BeatoImgThree src={Beato3} id="beato-img3"></BeatoImgThree>                    */}
                </BeatoImageContainer>
                <BeatoContentContainer id="beato-content">
                    <BeatoLogoImg src={BeatoLogo}/>
                    <SectionParagraph  width={"90%"} mobile={"80%"}>Good Health Company is a full-stack solution for patients with conditions like hair issues, sexual health, weight loss and skincare. GHC has its own men’s health products under the sub-brand ‘Mars’ and provides unlimited free consultations with their team of experts, and recommends a personalized course of treatment.</SectionParagraph>
                </BeatoContentContainer>         
        </BeatoWrap>
    );
};

export default PortfolioBeato;