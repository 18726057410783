import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TeamSlide from "../components/TeamSlide"
import { graphql, StaticQuery,Link } from "gatsby";
export default (props) => (
  <StaticQuery
    query={graphql`
    query {
      allWpTeam(
        filter: {teamTags: {nodes: {elemMatch: {name: {eq: "Core Team"}}}}}
        sort: {order: ASC, fields: date}
      ) {
        nodes {
          teamDetails {
            description
            designation
            linkedinUrl
            name
            image {
              mediaItemUrl
            }
          }
          teamTags {
            nodes {
              name
            }
          }
        }
      }
        }
    `}
    
    render={data => (  
      <Slider {...props.settings}>            
        {data.allWpTeam.nodes.map((person, index) => (
            <TeamSlide key={index} data={person}></TeamSlide>
        ))}
      </Slider>            
    )}
  />
)